import { useCallback, useEffect, useRef } from "react";
import { useSafeLayoutEffect } from "./useSafeLayoutEffect";

type UseDomEventListenerProps<K extends keyof HTMLElementEventMap> = {
  eventName: K;
  onFire: (ev: HTMLElementEventMap[K]) => void;
  element: HTMLElement | null;
};
export const useDomEventListener = <K extends keyof HTMLElementEventMap>({
  eventName,
  onFire,
  element,
}: UseDomEventListenerProps<K>) => {
  // メモリリーク対策用フラグ
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  // メモリリーク対策として、コンポーネントがマウント中の時のみイベントハンドラを実行する
  const onFireWrapperFn = useCallback(
    (ev: HTMLElementEventMap[K]) => {
      if (mounted.current) {
        onFire(ev);
      }
    },
    [onFire],
  );

  const detachRef = useRef<() => void | undefined>();

  // エレメントが初期化された時にイベントハンドラをアタッチする、
  // エレメントの参照が変わった場合はイベントハンドラをデタッチしてからアタッチし直す
  useSafeLayoutEffect(() => {
    if (element) {
      element.addEventListener(eventName, onFireWrapperFn);
      detachRef.current = () => {
        element.removeEventListener(eventName, onFireWrapperFn);
      };
    }
    return () => {
      detachRef.current?.();
    };
  }, [element, onFireWrapperFn]);
};
