import { useRef, useEffect, useState, useCallback } from "react";
import { useFlashMessageComponentState } from "./FlashMessageContext";
import clsx from "clsx";
import { useSafeLayoutEffect } from "../../hooks/useSafeLayoutEffect";
import { useDomEventListener } from "../../hooks/useDomEventListener";
import { DescriptionText } from "../DescriptionText";

// Main hooks
const useFlashMessage = () => {
  const { flashMessageState, closeMessage } = useFlashMessageComponentState();
  const domRef = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState(
    () => flashMessageState.status !== "none",
  );

  const onFlashMessageClosed = useCallback(
    (event: TransitionEvent) => {
      const element = event.target as HTMLDivElement;
      // FlashMessageが開いたときと閉じたときにtransitionendが走るので、閉じたときだけに限定する
      // heightのtransitionなので、offsetHeightを参照する
      if (element.offsetHeight === 0) {
        closeMessage();
      }
    },
    [closeMessage],
  );

  useDomEventListener({
    eventName: "transitionend",
    onFire: onFlashMessageClosed,
    element: domRef.current,
  });

  useEffect(() => {
    if (flashMessageState.status !== "none") {
      setVisible(true);
    }
  }, [flashMessageState.status]);

  useTriggerTimer({
    // flashMessageState.status を渡すとプリミティブ型は同じ値の更新が検知できないので、stateの参照をそのまま渡す
    dependencyValue: flashMessageState,
    shouldTriggerTimer: (val) => val.status !== "none",
    timeMs: 3000,
    onTimeout: () => {
      setVisible(false);
    },
  });

  const classNames = clsx("notification", {
    "is-success": flashMessageState.status === "success",
    "is-danger": flashMessageState.status === "error",
    "is-info": flashMessageState.status === "info",
    "notification--visible": visible,
  });

  const rootProps = {
    ref: domRef,
    className: classNames,
  };

  return {
    message: flashMessageState.message,
    rootProps,
  };
};

// Main component
export const FlashMessage = () => {
  const { rootProps, message } = useFlashMessage();

  return (
    <div {...rootProps}>
      <DescriptionText>{message ?? ""}</DescriptionText>
    </div>
  );
};

// helpers
type UseTriggerTimerProps<Value> = {
  dependencyValue: Value;
  shouldTriggerTimer: (val: Value) => boolean;
  timeMs: number;
  onTimeout: () => void;
};
const useTriggerTimer = <Value extends any>({
  dependencyValue,
  shouldTriggerTimer,
  timeMs,
  onTimeout,
}: UseTriggerTimerProps<Value>) => {
  // メモリリーク対策用フラグ
  const mountedRef = useRef(true);

  // コンポーネントアンマウント時にフラグをオフ
  useSafeLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const isTimerOnRef = useRef(false);
  const timerIDRef = useRef(-1);

  useSafeLayoutEffect(() => {
    const isTimerOn = shouldTriggerTimer(dependencyValue);
    // フラッシュメッセージが表示中に、再度表示をdispatchされた場合はタイマをリセットして測り直す
    if (isTimerOn) {
      window.clearTimeout(timerIDRef.current);
      timerIDRef.current = window.setTimeout(() => {
        // コンポーネントがマウント中の時のみコールバックを実行する
        if (mountedRef.current) {
          onTimeout();
        }
      }, timeMs);
    }
    // value が変更され、タイマーのトリガーがoffになったら現在のトリガーの状態と比較する
    // true -> false ならタイマークリア
    // false -> false なら何もしない
    if (!isTimerOn && isTimerOnRef.current) {
      window.clearTimeout(timerIDRef.current);
    }
    isTimerOnRef.current = isTimerOn;
  }, [dependencyValue]);
};
