import ApiClient, {
  UnauthorizedError,
  UnexpectedError,
} from "../../helpers/api";
import { useQuery } from "react-query";
import { Operator } from "../../domains/Operator";

type UseCurrentOperatorError = UnexpectedError | UnauthorizedError;
type UseCurrentOperatorApi = {
  currentOperator: Operator | null;
  isLoading: boolean;
  error: UseCurrentOperatorError | null;
};
export const useCurrentOperatorApi = (): UseCurrentOperatorApi => {
  const path = `/api/v1/me`;
  const result = useQuery<Operator, UseCurrentOperatorError>(
    path,
    async () => {
      const response = await ApiClient.get(path);
      if (response.ok) {
        const json = await response.json();
        return json.currentOperator as Operator;
      }
      if (response.status === 401) {
        throw new UnauthorizedError();
      }
      throw new UnexpectedError();
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  return {
    currentOperator: result.data ?? null,
    isLoading: result.isLoading,
    error: result.error,
  };
};
