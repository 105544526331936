import * as React from "react";
import { ErrorContent } from "./ErrorContent";

const NotFoundError = () => {
  return <p>404 Not Found.</p>;
};

export const NotFoundErrorView = () => {
  return (
    <ErrorContent
      title="ご指定のURLは無効になっています。"
      description={<NotFoundError />}
    />
  );
};
