import { NotFoundError, UnauthorizedError } from "../../helpers/api";
import { NotFoundErrorView } from "./NotFoundErrorView";
import { UnauthorizedErrorView } from "./UnauthorizedErrorView";
import { UnexpectedErrorView } from "./UnexpectedErrorView";

type Props = {
  error: Error | null;
};

export const ErrorView = ({ error }: Props) => {
  if (error === null) {
    return null;
  }

  if (error instanceof NotFoundError) {
    return <NotFoundErrorView />;
  }

  if (error instanceof UnauthorizedError) {
    return <UnauthorizedErrorView />;
  }

  return <UnexpectedErrorView err={error} />;
};
