import { ReactNode } from "react";

type Props = {
  title: ReactNode;
  description: ReactNode;
};

export const ErrorContent = ({ title, description }: Props) => (
  <div>
    <h2>{title}</h2>
    <div>{description}</div>
  </div>
);
