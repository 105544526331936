import { useRouter } from "next/router";

export const UnauthorizedErrorView = () => {
  const router = useRouter();
  if (typeof window !== "undefined") {
    const loginURL = new URL("/sign_in", process.env.BORON_URL);
    loginURL.search = new URLSearchParams({
      url: window.location.toString(),
    }).toString();
    router.push(loginURL);
  }
  return null;
};
