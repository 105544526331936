import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";
import { useRef } from "react";
import { AuthenticationProvider } from "../components/AuthenticationProvider";
import { HTTPErrorProvider } from "../components/ErrorView";
import { FlashMessageProvider } from "../components/FlashMessage";
import "../styles/globals.scss";
import Head from "next/head";

function MyApp({ Component, pageProps }: AppProps) {
  const queryClientRef = useRef<QueryClient | null>(null);
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  return (
    <>
      <Head>
        <title>Studyplus for School 入退室受付画面</title>
      </Head>
      <QueryClientProvider client={queryClientRef.current}>
        <FlashMessageProvider>
          <HTTPErrorProvider>
            <AuthenticationProvider>
              <div id="PostingMask" />
              <Component {...pageProps} />
            </AuthenticationProvider>
          </HTTPErrorProvider>
        </FlashMessageProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
